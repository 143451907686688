import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Cumenti si giogat?" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Intzerta su fueddu provendi <strong>6</strong> bortas. <br/>
      A pustis de dònnia prova su colori de is lìteras mudat po t’amostai cantu ses acanta a su fueddu de intzertai.
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300 info-rules">
      <strong>DONA A CURA</strong> a sa diferèntzia intra <strong>Z/TZ</strong>, <strong>X/SC</strong> e <strong>D/DD</strong>. <br />
      S’ <u>allobant</u> sceti is cunsonantis <strong>L, N, S, R, B, M</strong>.<br />
      Manixaus is arrègulas de <strong><a href="https://www.academiadesusardu.org/chi-siamo/su-sardu-standard/" target="_blank" rel="noreferrer" >SU SARDU STANDARD</a></strong>
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="correct"
        />
        <Cell value="I" />
        <Cell value="M" />
        <Cell value="B" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Sa lìtera L est in su fueddu e in su logu suu.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" />
        <Cell value="U" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="D" />
        <Cell value="I" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Sa lìtera N est in su fueddu ma no est in su logu suu.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="M" />
        <Cell value="A" />
        <Cell value="N" />
        <Cell isRevealing={true} isCompleted={true} value="C" status="absent" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Sa lìtera C no est in su fueddu.
      </p>

      <p className="mt-6 italic text-xs text-gray-500 dark:text-gray-300">
        <hr></hr>
        <strong>Sardle.it</strong> 2022 - v.0 - versione modificata da <strong>Uncoventional Sardinia</strong> e <strong>Strias Arestis</strong> dedicata alle parole in Sardo. <br />
       -  <a href="https://www.colorammiscallonis.it/" target="_blank" rel="noreferrer">Scopri il nostro libro per <strong>rilassarti frastimando!</strong></a> -
        <hr></hr>
      Il software open source del gioco è scaricabile da {' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
          target="blank"
          rel="nofollow"
        >
          qui
        </a>!
      </p>
    </BaseModal>
  )
}
