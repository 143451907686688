export const VALID_GUESSES = [
  'megus',
  'teula',
  'sestu',
  'deghe',
  'boinu',
  'anneu',
  'leadu',
  'legai',
  'asolu',
  'luinu',
  'orulu',
  'clava',
  'tremi',
  'coiri',
  'cassa',
  'giobu',
  'carga',
  'posca',
  'ditzu',
  'tupai',
  'sciri',
  'virga',
  'folma',
  'bruta',
  'enina',
  'reste',
  'andia',
  'emida',
  'regnu',
  'fiotu',
  'pannu',
  'tussi',
  'lessu',
  'censu',
  'piegu',
  'perdu',
  'orriu',
  'seosu',
  'bassa',
  'coana',
  'suria',
  'zinnu',
  'lelzu',
  'bagnu',
  'creja',
  'istis',
  'ruedu',
  'latzu',
  'murta',
  'ruinu',
  'trinu',
  'frade',
  'tenea',
  'sidiu',
  'oliai',
  'sultu',
  'procu',
  'bogai',
  'lusia',
  'uspia',
  'umanu',
  'biaxi',
  'acidu',
  'fariu',
  'chini',
  'cubas',
  'boeri',
  'entzu',
  'leere',
  'felta',
  'spola',
  'falda',
  'telus',
  'ghite',
  'esodu',
  'signu',
  'catza',
  'ternu',
  'iradu',
  'nenia',
  'ferta',
  'turnu',
  'luisu',
  'scuta',
  'valia',
  'ferze',
  'gruxi',
  'gioga',
  'anela',
  'tortu',
  'chiri',
  'furtu',
  'elare',
  'burru',
  'suliu',
  'birbu',
  'auppu',
  'scusi',
  'tallu',
  'ispia',
  'perra',
  'pensu',
  'telpe',
  'caula',
  'bussa',
  'cufia',
  'trufa',
  'dente',
  'nughe',
  'calcu',
  'sudai',
  'sorri',
  'liceu',
  'tzuai',
  'molai',
  'caliu',
  'pignu',
  'stula',
  'furru',
  'mossu',
  'unida',
  'bessu',
  'pipia',
  'baule',
  'lapis',
  'clobu',
  'pulte',
  'malza',
  'cuili',
  'arvau',
  'purga',
  'alziu',
  'mummu',
  'garbu',
  'pighe',
  'feona',
  'inviu',
  'banga',
  'billa',
  'pione',
  'fastu',
  'monzu',
  'patzu',
  'messa',
  'pudre',
  'paone',
  'fadau',
  'salzu',
  'borra',
  'banzu',
  'troga',
  'virde',
  'uniju',
  'fante',
  'asilu',
  'scalu',
  'porcu',
  'miliu',
  'larva',
  'legau',
  'trenu',
  'tzopi',
  'pausa',
  'criai',
  'perla',
  'cerfa',
  'peiga',
  'media',
  'mistu',
  'ziriu',
  'belda',
  'griju',
  'sumiu',
  'petus',
  'dosai',
  'ditzi',
  'incue',
  'alliu',
  'luego',
  'menta',
  'tassa',
  'tianu',
  'arbus',
  'nadiu',
  'liaga',
  'scacu',
  'bogau',
  'zubai',
  'trina',
  'ativu',
  'finca',
  'aibbo',
  'impiu',
  'merce',
  'benas',
  'tedeu',
  'birdi',
  'fadai',
  'stocu',
  'urrei',
  'fuiri',
  'nuadu',
  'bumbu',
  'giaga',
  'zanga',
  'tolla',
  'duina',
  'zintu',
  'voste',
  'venia',
  'babbu',
  'ulare',
  'forru',
  'ordiu',
  'fiama',
  'pesai',
  'sorde',
  'stica',
  'scusa',
  'isadu',
  'basta',
  'gioja',
  'alcia',
  'domai',
  'sughe',
  'asone',
  'omini',
  'frocu',
  'diada',
  'laras',
  'chizu',
  'ventu',
  'nanna',
  'frida',
  'studa',
  'belme',
  'depiu',
  'bodas',
  'pardu',
  'grima',
  'liviu',
  'cojua',
  'briga',
  'argia',
  'peudu',
  'burla',
  'sisia',
  'zoera',
  'mudau',
  'ballu',
  'beniu',
  'prima',
  'coiau',
  'cultu',
  'estru',
  'fadre',
  'rastu',
  'mauru',
  'azigu',
  'binda',
  'fiuda',
  'cerri',
  'druda',
  'ungra',
  'dedda',
  'cagai',
  'farda',
  'torra',
  'gioba',
  'aliga',
  'pinza',
  'lerzu',
  'bessi',
  'caldu',
  'genti',
  'aladu',
  'manzu',
  'surdu',
  'nascu',
  'asuba',
  'erone',
  'suinu',
  'rotza',
  'cropu',
  'cipro',
  'ranas',
  'pipai',
  'scata',
  'tughe',
  'azada',
  'niaxi',
  'mundu',
  'moddi',
  'seriu',
  'vulza',
  'agudu',
  'bassu',
  'muslu',
  'buleu',
  'salve',
  'froca',
  'berbu',
  'maiia',
  'raina',
  'broia',
  'cuidu',
  'legiu',
  'crupa',
  'certu',
  'bufai',
  'arbiu',
  'pisci',
  'sutzu',
  'aurdu',
  'ofesa',
  'cobru',
  'senza',
  'spiga',
  'turcu',
  'orizu',
  'nonnu',
  'elias',
  'senau',
  'prupa',
  'amina',
  'miria',
  'telau',
  'biura',
  'cascu',
  'spula',
  'millu',
  'faria',
  'pinni',
  'amadu',
  'depus',
  'craru',
  'nuosu',
  'palcu',
  'pastu',
  'medru',
  'baosu',
  'futiu',
  'votai',
  'arega',
  'alere',
  'toncu',
  'trotu',
  'desde',
  'sissi',
  'festa',
  'crebu',
  'peddi',
  'lance',
  'ancua',
  'teiiu',
  'crepa',
  'orire',
  'sagra',
  'turri',
  'extra',
  'iscra',
  'mutuu',
  'prexu',
  'orale',
  'musca',
  'trona',
  'chilu',
  'faule',
  'ornau',
  'ogada',
  'burca',
  'irare',
  'gilla',
  'fraci',
  'lughe',
  'bugnu',
  'chelu',
  'deris',
  'narba',
  'ispeu',
  'silva',
  'balva',
  'molle',
  'seula',
  'ruolu',
  'neghe',
  'antua',
  'prama',
  'adulu',
  'nafta',
  'opacu',
  'lemma',
  'pediu',
  'iscia',
  'niciu',
  'irada',
  'gastu',
  'sobru',
  'azotu',
  'centu',
  'crine',
  'balca',
  'bruxa',
  'tegus',
  'calau',
  'cicai',
  'mruva',
  'altia',
  'passa',
  'tirau',
  'lissu',
  'pompa',
  'ovadu',
  'tasca',
  'furiu',
  'preda',
  'coiai',
  'gredu',
  'onori',
  'bidru',
  'ambos',
  'baure',
  'crara',
  'belza',
  'bijbu',
  'atopu',
  'mutos',
  'petza',
  'candu',
  'rampu',
  'tzocu',
  'bitiu',
  'casta',
  'ididu',
  'fiebi',
  'ededu',
  'lecas',
  'bilde',
  'colai',
  'tisia',
  'meigu',
  'fintu',
  'cuebi',
  'bentu',
  'berda',
  'brunu',
  'viola',
  'mandu',
  'cloro',
  'sighi',
  'zotza',
  'dicia',
  'spiai',
  'privu',
  'noina',
  'orgiu',
  'calca',
  'scuau',
  'gelau',
  'barda',
  'boleu',
  'cleru',
  'umori',
  'opinu',
  'giura',
  'baldu',
  'galia',
  'copla',
  'robba',
  'prupu',
  'roseu',
  'grifu',
  'niera',
  'nuscu',
  'milla',
  'lumbu',
  'stufa',
  'reatu',
  'micia',
  'peumu',
  'arula',
  'birba',
  'bambu',
  'onesa',
  'gomai',
  'odida',
  'scera',
  'vistu',
  'barva',
  'spera',
  'pudai',
  'verbu',
  'punzu',
  'nessu',
  'etada',
  'silus',
  'praca',
  'baone',
  'picai',
  'tacau',
  'pisca',
  'pulpa',
  'atriu',
  'runza',
  'frana',
  'spudu',
  'diana',
  'tacai',
  'testu',
  'obiai',
  'cussu',
  'merie',
  'cueru',
  'suile',
  'grega',
  'suiri',
  'uteru',
  'brodu',
  'caspa',
  'dardu',
  'boghe',
  'giaja',
  'aleve',
  'betzi',
  'ciatu',
  'frusa',
  'linea',
  'parua',
  'tinta',
  'binsu',
  'lissa',
  'mansu',
  'brama',
  'unire',
  'porru',
  'dainu',
  'bullu',
  'emula',
  'rotzu',
  'giara',
  'caule',
  'epicu',
  'luire',
  'tirai',
  'bantu',
  'liare',
  'calix',
  'lista',
  'cerda',
  'abriu',
  'aloes',
  'solas',
  'calmu',
  'zoadu',
  'bofiu',
  'manta',
  'crobu',
  'mitra',
  'furbu',
  'runfa',
  'zichi',
  'pintu',
  'esciu',
  'suspu',
  'dorai',
  'fauzu',
  'cofre',
  'asseu',
  'lioni',
  'crisi',
  'aradu',
  'capra',
  'seina',
  'lianu',
  'renta',
  'ruaza',
  'plata',
  'fuetu',
  'parau',
  'orina',
  'ficas',
  'eriza',
  'farga',
  'papau',
  'falsu',
  'torou',
  'diunu',
  'entre',
  'grapa',
  'mancu',
  'cedru',
  'debbe',
  'clavu',
  'curva',
  'buliu',
  'pruga',
  'denou',
  'esame',
  'zubba',
  'suaru',
  'terna',
  'austu',
  'aitzu',
  'ghisu',
  'oreri',
  'belai',
  'ragas',
  'rumba',
  'sinna',
  'rista',
  'orija',
  'zirca',
  'pioca',
  'otoni',
  'cautu',
  'medra',
  'scala',
  'feriu',
  'arena',
  'gocia',
  'muinu',
  'retza',
  'sceti',
  'costa',
  'linfa',
  'asiau',
  'tziri',
  'rennu',
  'carra',
  'mardi',
  'gianu',
  'ojare',
  'coldu',
  'pulpu',
  'negru',
  'friga',
  'proga',
  'arrui',
  'chiza',
  'manna',
  'boara',
  'bocia',
  'predi',
  'pigau',
  'lusbe',
  'lardu',
  'gureu',
  'puddu',
  'cinta',
  'penau',
  'bulla',
  'batiu',
  'cignu',
  'tintu',
  'luere',
  'launa',
  'zumiu',
  'callu',
  'opera',
  'amitu',
  'ruscu',
  'sorbu',
  'panas',
  'amada',
  'picau',
  'colzu',
  'suave',
  'farta',
  'mruxa',
  'asima',
  'zoare',
  'pisti',
  'ruere',
  'murza',
  'fidai',
  'laicu',
  'laite',
  'luxia',
  'trudu',
  'sogra',
  'dondo',
  'missa',
  'mecia',
  'cudis',
  'parti',
  'deinu',
  'vinca',
  'burdu',
  'linna',
  'frazu',
  'fundu',
  'stori',
  'gagiu',
  'domus',
  'scutu',
  'rollu',
  'rapai',
  'staiu',
  'fiagu',
  'tzugu',
  'flore',
  'trigu',
  'tumbu',
  'gagia',
  'dunga',
  'tarea',
  'tropa',
  'bufau',
  'fiscu',
  'tecla',
  'gessa',
  'sansa',
  'turbu',
  'girau',
  'bulzu',
  'stadu',
  'giazu',
  'sidis',
  'voiga',
  'lovia',
  'turre',
  'pudda',
  'luciu',
  'rosca',
  'imbia',
  'merda',
  'perou',
  'crudu',
  'cerau',
  'usura',
  'cedra',
  'pitzu',
  'becia',
  'cociu',
  'iscai',
  'vista',
  'musas',
  'liada',
  'reali',
  'narai',
  'fillu',
  'tinnu',
  'chena',
  'biada',
  'sicai',
  'enniu',
  'sulai',
  'bista',
  'barra',
  'renga',
  'niare',
  'alabu',
  'siriu',
  'crocu',
  'larga',
  'aorta',
  'burgu',
  'magru',
  'limbu',
  'nobia',
  'anelu',
  'serpe',
  'sruba',
  'ghiai',
  'gairu',
  'saldu',
  'tapau',
  'enosu',
  'dante',
  'isada',
  'brabu',
  'porca',
  'stoia',
  'ulumu',
  'afidu',
  'pedra',
  'leura',
  'temiu',
  'croga',
  'lella',
  'inseu',
  'buidu',
  'noeru',
  'sessu',
  'teste',
  'nuoru',
  'tandu',
  'snuai',
  'coule',
  'boidu',
  'tinca',
  'pecau',
  'betai',
  'campu',
  'conza',
  'corpu',
  'cunnu',
  'vitzu',
  'ruspu',
  'acuai',
  'atimu',
  'santa',
  'melzu',
  'ghiru',
  'pasmu',
  'truba',
  'lupiu',
  'omine',
  'zuava',
  'sanza',
  'nignu',
  'mirra',
  'corti',
  'carda',
  'rasai',
  'aoadu',
  'rudiu',
  'nardu',
  'sepia',
  'grifa',
  'siddu',
  'paule',
  'prova',
  'matai',
  'pigia',
  'colte',
  'fossu',
  'murru',
  'martu',
  'cessu',
  'segau',
  'fanni',
  'pibia',
  'conca',
  'luscu',
  'ghisa',
  'inzia',
  'simai',
  'rusca',
  'emulu',
  'surra',
  'vintu',
  'zubau',
  'scuru',
  'timiu',
  'cloba',
  'niadu',
  'foiga',
  'ribes',
  'sanai',
  'scexa',
  'crere',
  'penai',
  'bascu',
  'figau',
  'curpa',
  'sonnu',
  'prata',
  'aurru',
  'burda',
  'metro',
  'sonus',
  'padru',
  'origa',
  'boria',
  'forra',
  'feghe',
  'assai',
  'rassa',
  'cambu',
  'diciu',
  'faltu',
  'pagnu',
  'epuru',
  'serra',
  'bustu',
  'furia',
  'balia',
  'pupia',
  'grefa',
  'polca',
  'ardia',
  'palla',
  'tiaza',
  'turra',
  'acisa',
  'messe',
  'droga',
  'salvu',
  'eletu',
  'norma',
  'sceda',
  'otavu',
  'fidau',
  'oridu',
  'suadu',
  'ierru',
  'istai',
  'aorru',
  'cisne',
  'busta',
  'suciu',
  'pesta',
  'zebra',
  'tretu',
  'queru',
  'dundu',
  'murra',
  'chemu',
  'mitza',
  'lauru',
  'dindo',
  'cuitu',
  'multa',
  'birga',
  'raida',
  'arreu',
  'troja',
  'monta',
  'bultu',
  'avisu',
  'abigu',
  'vinsu',
  'croba',
  'aludu',
  'lussu',
  'riaga',
  'scafa',
  'rubiu',
  'luadu',
  'banda',
  'gaita',
  'dindi',
  'burga',
  'cirra',
  'curai',
  'troju',
  'fatau',
  'anice',
  'scapu',
  'tebiu',
  'fiara',
  'niada',
  'pratu',
  'perca',
  'apena',
  'ponte',
  'berza',
  'efetu',
  'runda',
  'acatu',
  'uvara',
  'socra',
  'conos',
  'ajulu',
  'dignu',
  'selva',
  'munza',
  'fresu',
  'museu',
  'cuviu',
  'bolai',
  'sagna',
  'gassa',
  'calma',
  'prenu',
  'asulu',
  'pompu',
  'cidru',
  'zanda',
  'dianu',
  'cursu',
  'bautu',
  'tratu',
  'unicu',
  'circa',
  'cedda',
  'arana',
  'feura',
  'frabu',
  'cunge',
  'arrea',
  'gramu',
  'cispa',
  'bruxu',
  'luidu',
  'viuda',
  'milli',
  'concu',
  'talla',
  'dinai',
  'cicca',
  'primu',
  'buicu',
  'castu',
  'cutis',
  'feudu',
  'sicia',
  'pista',
  'pienu',
  'sotzu',
  'maone',
  'grana',
  'duana',
  'fantu',
  'latia',
  'largu',
  'majia',
  'zesta',
  'zinta',
  'balza',
  'atzua',
  'pauli',
  'zonza',
  'conzu',
  'peoru',
  'canna',
  'limai',
  'vinti',
  'tanau',
  'eaghi',
  'palto',
  'bulbu',
  'moina',
  'gnocu',
  'tinza',
  'dossu',
  'tzira',
  'partu',
  'trupa',
  'puntu',
  'liera',
  'musci',
  'edile',
  'tregu',
  'cudda',
  'tupau',
  'tudai',
  'mimas',
  'cabra',
  'lillu',
  'crema',
  'giuai',
  'pidai',
  'culiu',
  'peone',
  'scatu',
  'calai',
  'albis',
  'dengu',
  'dopiu',
  'nuada',
  'gatou',
  'sonai',
  'tragu',
  'leali',
  'triga',
  'cibbu',
  'alipe',
  'truta',
  'bumba',
  'maccu',
  'odire',
  'tungu',
  'spina',
  'lebra',
  'ovale',
  'liuru',
  'museo',
  'amiga',
  'calta',
  'gosau',
  'zibbu',
  'sumba',
  'neula',
  'erede',
  'braba',
  'cheja',
  'raiga',
  'bancu',
  'dansa',
  'friri',
  'puliu',
  'palau',
  'calda',
  'muere',
  'ornai',
  'tacia',
  'filau',
  'abbau',
  'dobbo',
  'mosta',
  'tonte',
  'deleu',
  'setiu',
  'ciopa',
  'tiria',
  'sorsu',
  'luxiu',
  'bricu',
  'ultra',
  'ospia',
  'estiu',
  'finiu',
  'neale',
  'valiu',
  'traca',
  'giogu',
  'comma',
  'coidu',
  'amplu',
  'nodia',
  'suntu',
  'sisse',
  'velau',
  'scolu',
  'tella',
  'miniu',
  'overa',
  'labia',
  'sorba',
  'abidu',
  'domau',
  'conti',
  'innui',
  'elimu',
  'autzu',
  'anici',
  'zembo',
  'trama',
  'prana',
  'istoa',
  'crasi',
  'erina',
  'eredi',
  'grogu',
  'perru',
  'gosai',
  'gaude',
  'roida',
  'possa',
  'forti',
  'cuare',
  'punza',
  'busca',
  'naviu',
  'oburu',
  'caltu',
  'apigu',
  'basai',
  'trexi',
  'bisai',
  'odiai',
  'limau',
  'bulgu',
  'basca',
  'famen',
  'anida',
  'esitu',
  'bante',
  'beciu',
  'stola',
  'preca',
  'imbiu',
  'costu',
  'servu',
  'matza',
  'colpu',
  'pasca',
  'anima',
  'scuda',
  'neadu',
  'nemos',
  'cenau',
  'laina',
  'bidda',
  'baula',
  'cadra',
  'boddo',
  'binsa',
  'folca',
  'caddu',
  'soltu',
  'aminu',
  'azisa',
  'ozosu',
  'luare',
  'ojanu',
  'balba',
  'valta',
  'pistu',
  'galla',
  'soniu',
  'toscu',
  'aridu',
  'prosu',
  'benes',
  'cerea',
  'ranza',
  'baina',
  'bociu',
  'rombu',
  'peale',
  'serca',
  'ampua',
  'laere',
  'seidu',
  'moida',
  'lenti',
  'motzo',
  'negus',
  'naspa',
  'mostu',
  'sanna',
  'discu',
  'bicai',
  'piabi',
  'tontu',
  'otziu',
  'umili',
  'onnia',
  'stibu',
  'mollu',
  'blavu',
  'fumai',
  'lascu',
  'zente',
  'basau',
  'tzegu',
  'catre',
  'birde',
  'zoada',
  'rissa',
  'zumba',
  'arare',
  'sfera',
  'fridu',
  'vulgu',
  'durci',
  'scola',
  'voghe',
  'avatu',
  'trija',
  'velza',
  'scudu',
  'navra',
  'durai',
  'tzacu',
  'muizu',
  'obida',
  'agaju',
  'postu',
  'traja',
  'collu',
  'lobru',
  'bangu',
  'zotzu',
  'frisa',
  'mengu',
  'bulte',
  'badas',
  'sensu',
  'dillu',
  'pernu',
  'soldu',
  'aesca',
  'giagu',
  'targa',
  'bafus',
  'violu',
  'loria',
  'nenna',
  'chida',
  'codra',
  'resau',
  'beste',
  'ortiu',
  'gione',
  'apoca',
  'pasiu',
  'edade',
  'belea',
  'citai',
  'panna',
  'cauda',
  'dracu',
  'nudda',
  'naulu',
  'amori',
  'ascia',
  'druci',
  'paghe',
  'matiu',
  'froma',
  'galiu',
  'pagai',
  'fetze',
  'pepei',
  'neosu',
  'tzicu',
  'pruma',
  'badia',
  'tripa',
  'fabiu',
  'salsa',
  'liosu',
  'luida',
  'creva',
  'bostu',
  'ribba',
  'ondra',
  'amega',
  'cuone',
  'aureu',
  'asadu',
  'lavru',
  'idulu',
  'ragiu',
  'coitu',
  'rodiu',
  'megai',
  'nunza',
  'reina',
  'santu',
  'zorbu',
  'agone',
  'ungia',
  'mantu',
  'mutza',
  'axedu',
  'palmu',
  'amidu',
  'almia',
  'sfatu',
  'tibbe',
  'zarra',
  'giudu',
  'bella',
  'scena',
  'liadu',
  'tumba',
  'serva',
  'ideai',
  'crabu',
  'troni',
  'zella',
  'iodiu',
  'niosu',
  'diosu',
  'reulu',
  'fibra',
  'ianna',
  'palpu',
  'iroxu',
  'fasca',
  'antis',
  'lupia',
  'editu',
  'sinza',
  'andai',
  'ladus',
  'paliu',
  'ledre',
  'biazu',
  'camba',
  'greze',
  'taula',
  'fresa',
  'panga',
  'mossa',
  'cifra',
  'sardu',
  'sarau',
  'zertu',
  'cameu',
  'epica',
  'gobbu',
  'spuma',
  'anzua',
  'curvu',
  'gosmo',
  'scopu',
  'bintu',
  'umidu',
  'umbra',
  'curau',
  'srucu',
  'sartu',
  'riere',
  'rusta',
  'gravi',
  'traia',
  'filza',
  'brugu',
  'mirai',
  'ninfa',
  'ganza',
  'lautu',
  'traga',
  'libru',
  'missi',
  'axina',
  'undai',
  'foghe',
  'cupas',
  'cauli',
  'coddu',
  'braxu',
  'farri',
  'nessi',
  'aleni',
  'betzu',
  'sorte',
  'poita',
  'ibbia',
  'gridu',
  'stani',
  'dindu',
  'malca',
  'donnu',
  'donai',
  'piana',
  'murau',
  'pregu',
  'piaga',
  'idolu',
  'punna',
  'ressu',
  'grecu',
  'sorti',
  'arriu',
  'asuta',
  'sorre',
  'nullu',
  'valle',
  'bonas',
  'preju',
  'turta',
  'medau',
  'marca',
  'muila',
  'furca',
  'dannu',
  'pramu',
  'mentu',
  'fassa',
  'docia',
  'biadu',
  'sirba',
  'quota',
  'gallu',
  'mallu',
  'galza',
  'araxi',
  'imbre',
  'gessu',
  'nonna',
  'muscu',
  'zainu',
  'sudau',
  'ponne',
  'arpia',
  'rigai',
  'bicus',
  'proto',
  'doadu',
  'pampe',
  'guetu',
  'ovali',
  'fuida',
  'iutzu',
  'massu',
  'guida',
  'fuire',
  'edadi',
  'cuada',
  'stima',
  'claru',
  'geniu',
  'buada',
  'bombu',
  'ovulu',
  'faunu',
  'erege',
  'pioza',
  'punga',
  'proja',
  'bursa',
  'linia',
  'spagu',
  'coita',
  'treta',
  'istiu',
  'ociri',
  'rugru',
  'faula',
  'loinu',
  'setzi',
  'lentu',
  'notai',
  'odidu',
  'abica',
  'dexiu',
  'borta',
  'detzi',
  'mortu',
  'unica',
  'dilla',
  'etale',
  'matzu',
  'scavu',
  'telga',
  'molla',
  'isura',
  'ratza',
  'tocau',
  'neada',
  'negai',
  'palas',
  'bravu',
  'fuina',
  'cuadu',
  'carta',
  'leada',
  'praju',
  'laidu',
  'lolla',
  'prite',
  'intro',
  'tropu',
  'crusu',
  'velai',
  'variu',
  'benda',
  'fiadu',
  'tastu',
  'meria',
  'arida',
  'uniga',
  'coudu',
  'bitzu',
  'metru',
  'zirfa',
  'sceru',
  'biesa',
  'bastu',
  'ajana',
  'fartu',
  'fasci',
  'suiga',
  'enadi',
  'lunis',
  'petzi',
  'essiu',
  'diosa',
  'siera',
  'cantu',
  'tanti',
  'arcau',
  'ahide',
  'corra',
  'cainu',
  'daina',
  'laude',
  'megas',
  'dughe',
  'gelai',
  'giulu',
  'fradi',
  'drita',
  'vinza',
  'nitru',
  'serpi',
  'croxu',
  'foras',
  'bolta',
  'morsa',
  'ateru',
  'ciapa',
  'ajone',
  'diuna',
  'tundu',
  'turdu',
  'egadu',
  'guasi',
  'gruta',
  'tridu',
  'iride',
  'buldu',
  'solfa',
  'colce',
  'drama',
  'buela',
  'travi',
  'frenu',
  'dogma',
  'pianu',
  'parte',
  'isare',
  'ariga',
  'surtu',
  'minca',
  'tiara',
  'aerru',
  'arviu',
  'amigu',
  'braxi',
  'momoi',
  'caria',
  'lorus',
  'spacu',
  'tentu',
  'frori',
  'pasai',
  'barzu',
  'essia',
  'sparu',
  'narva',
  'secau',
  'belvu',
  'issiu',
  'prosa',
  'domma',
  'tzopu',
  'famau',
  'nausa',
  'ariai',
  'miopi',
  'crofu',
  'ligas',
  'pelau',
  'sticu',
  'betua',
  'creba',
  'lanzu',
  'alidu',
  'vastu',
  'innoi',
  'aurra',
  'turpu',
  'grisu',
  'arina',
  'vessu',
  'maulu',
  'podit',
  'sfida',
  'ganga',
  'lados',
  'roere',
  'gestu',
  'nanni',
  'pelfa',
  'china',
  'spesa',
  'erria',
  'coeta',
  'cistu',
  'rosta',
  'ruina',
  'bubua',
  'aiscu',
  'vidru',
  'antia',
  'cariu',
  'budda',
  'solde',
  'alinu',
  'tiaca',
  'bione',
  'enale',
  'pruna',
  'breve',
  'sraca',
  'leona',
  'litra',
  'mezus',
  'cuida',
  'addea',
  'cilai',
  'miulu',
  'plaia',
  'conge',
  'turma',
  'puleu',
  'ojada',
  'culzu',
  'tamen',
  'berme',
  'presa',
  'diora',
  'asare',
  'beulu',
  'rampa',
  'befai',
  'podda',
  'etere',
  'osare',
  'ispau',
  'gradu',
  'menga',
  'boliu',
  'donau',
  'pecai',
  'beatu',
  'brivu',
  'ajozi',
  'faina',
  'luada',
  'ororu',
  'cosso',
  'fuste',
  'melmu',
  'parai',
  'ieunu',
  'isciu',
  'rudeu',
  'lofiu',
  'cintu',
  'runcu',
  'farsu',
  'neare',
  'meite',
  'avesu',
  'acuau',
  'aioni',
  'haere',
  'crobi',
  'versu',
  'pulia',
  'epoca',
  'arada',
  'eaite',
  'norte',
  'oltra',
  'scafu',
  'gergu',
  'binza',
  'cropa',
  'missu',
  'lassa',
  'broca',
  'crica',
  'marra',
  'fertu',
  'murcu',
  'raspu',
  'pretu',
  'sfogu',
  'pesau',
  'fruja',
  'randa',
  'peugu',
  'sapiu',
  'turba',
  'noria',
  'moviu',
  'berre',
  'dicai',
  'memei',
  'sceta',
  'atomu',
  'nachi',
  'culpa',
  'zanzi',
  'fuidu',
  'terru',
  'braja',
  'labai',
  'giuru',
  'longu',
  'agata',
  'talcu',
  'fessu',
  'tubbu',
  'restu',
  'aunzu',
  'fusti',
  'fragu',
  'binti',
  'lamai',
  'ritzu',
  'posta',
  'feltu',
  'tifai',
  'coscu',
  'papai',
  'ferru',
  'atesu',
  'crepu',
  'cucia',
  'credu',
  'animu',
  'piabo',
  'pudau',
  'orida',
  'elogu',
  'onore',
  'annou',
  'tenta',
  'frase',
  'frena',
  'filai',
  'raidu',
  'lepra',
  'pipiu',
  'fagiu',
  'crecu',
  'lindu',
  'banca',
  'ghiau',
  'agitu',
  'minzi',
  'colva',
  'salia',
  'ajadu',
  'coeru',
  'granu',
  'zurra',
  'furau',
  'conte',
  'contu',
  'codda',
  'mania',
  'morti',
  'fracu',
  'moere',
  'sarpa',
  'prole',
  'codia',
  'imitu',
  'scofa',
  'veras',
  'finis',
  'acusa',
  'coale',
  'stupa',
  'ajanu',
  'duplu',
  'sanau',
  'fuscu',
  'carre',
  'golfu',
  'giaru',
  'lebiu',
  'gasie',
  'aspru',
  'marcu',
  'blusa',
  'anzia',
  'fritu',
  'chera',
  'palia',
  'truma',
  'ganzu',
  'aligu',
  'finas',
  'ebanu',
  'clero',
  'suere',
  'giaju',
  'griva',
  'memme',
  'larea',
  'infra',
  'eremu',
  'melai',
  'besti',
  'salmu',
  'estia',
  'mussi',
  'tardu',
  'passu',
  'fallu',
  'dalia',
  'tanca',
  'loscu',
  'magnu',
  'langa',
  'tilla',
  'doare',
  'vagai',
  'tersu',
  'sordi',
  'nutzu',
  'chito',
  'magiu',
  'catzu',
  'bardu',
  'bresu',
  'iscou',
  'linza',
  'tostu',
  'falcu',
  'stepa',
  'bussu',
  'valda',
  'pranu',
  'cenai',
  'falai',
  'avaru',
  'spreu',
  'ebrea',
  'ciulu',
  'totue',
  'cuila',
  'unfiu',
  'buscu',
  'citiu',
  'piuma',
  'astru',
  'alenu',
  'cubbu',
  'codru',
  'tichi',
  'cuddu',
  'bistu',
  'fanua',
  'furai',
  'ciciu',
  'sustu',
  'zutzu',
  'fissu',
  'pendi',
  'fitzu',
  'inibe',
  'mares',
  'benzu',
  'poema',
  'cerbu',
  'duale',
  'ancus',
  'tzega',
  'tonca',
  'burra',
  'pillu',
  'margu',
  'caidu',
  'zosso',
  'erbai',
  'boldu',
  'laera',
  'nassa',
  'liutu',
  'ozera',
  'posai',
  'liuda',
  'olere',
  'summu',
  'mulza',
  'opuru',
  'atumu',
  'cadru',
  'stufu',
  'oglia',
  'curia',
  'ruone',
  'mutzu',
  'petia',
  'prode',
  'lucru',
  'tunnu',
  'fangu',
  'ufanu',
  'leori',
  'zotzo',
  'sedda',
  'litru',
  'pibiu',
  'nuera',
  'lisai',
  'osiat',
  'lutzu',
  'balga',
  'branu',
  'merzu',
  'frimu',
  'bandu',
  'usadu',
  'pitza',
  'pirri',
  'raore',
  'domas',
  'curzu',
  'giomo',
  'corru',
  'bramu',
  'craca',
  'gratu',
  'irocu',
  'afitu',
  'pelea',
  'multu',
  'budru',
  'altea',
  'gustu',
  'scuai',
  'intra',
  'utili',
  'braga',
  'girai',
  'ovaja',
  'vilde',
  'sonau',
  'enugu',
  'fumiu',
  'saltu',
  'abili',
  'risma',
  'obitu',
  'sidru',
  'ronza',
  'fresi',
  'fungu',
  'forsi',
  'miali',
  'carri',
  'anadi',
  'piena',
  'balla',
  'murai',
  'untza',
  'nadia',
  'isviu',
  'lisca',
  'astiu',
  'tronu',
  'rassu',
  'noxiu',
  'rondo',
  'cafei',
  'raspa',
  'putzi',
  'gagnu',
  'muida',
  'orare',
  'sulcu',
  'ghiju',
  'zincu',
  'bellu',
  'dragu',
  'flagu',
  'daghi',
  'flema',
  'piumu',
  'causa',
  'evitu',
  'afetu',
  'benai',
  'olada',
  'morva',
  'obera',
  'prumu',
  'cromo',
  'frutu',
  'friau',
  'pigai',
  'carru',
  'falzu',
  'mannu',
  'nemus',
  'dabba',
  'zumpu',
  'petzu',
  'abuzu',
  'nadre',
  'ninni',
  'tocai',
  'cadia',
  'piotu',
  'cursa',
  'fanta',
  'frina',
  'molma',
  'palma',
  'mediu',
  'lotai',
  'ostia',
  'spegu',
  'gabia',
  'uniri',
  'raere',
  'talau',
  'zenia',
  'steca',
  'molva',
  'presu',
  'calza',
  'alire',
  'geunu',
  'roidu',
  'draga',
  'gregu',
  'rangu',
  'clima',
  'poete',
  'undau',
  'flota',
  'sedia',
  'cuota',
  'muilu',
  'viscu',
  'crima',
  'bremi',
  'sussa',
  'pradu',
  'aldia',
  'muire',
  'fraca',
  'trota',
  'anade',
  'doada',
  'atacu',
  'ampiu',
  'cassi',
  'scrau',
  'maria',
  'porta',
  'calvu',
  'scoma',
  'lignu',
  'moria',
  'nadai',
  'aenzu',
  'nuare',
  'semmu',
  'tufai',
  'guisa',
  'tassu',
  'scotu',
  'llaor',
  'tabba',
  'punta',
  'ociau',
  'signa',
  'curtu',
  'burza',
  'pasta',
  'reidu',
  'guldu',
  'stofa',
  'niunu',
  'chica',
  'rumbu',
  'tampu',
  'ojadu',
  'biviu',
  'cheva',
  'ponti',
  'faghe',
  'decai',
  'copia',
  'fiala',
  'evasu',
  'bueta',
  'merca',
  'idili',
  'azimu',
  'frota',
  'famiu',
  'perso',
  'iscau',
  'nenno',
  'sotza',
  'sosoi',
  'soliu',
  'amenu',
  'ninna',
  'credo',
  'aundi',
  'lampu',
  'terzu',
  'saeta',
  'stiba',
  'catra',
  'secai',
  'farra',
  'leone',
  'preta',
  'piatu',
  'suare',
  'noale',
  'saigu',
  'peada',
  'sviai',
  'guale',
  'lieru',
  'ebreu',
  'clusu',
  'salza',
  'chene',
  'barri',
  'paura',
  'mafra',
  'alore',
  'tresi',
  'rodeu',
  'esatu',
  'stili',
  'leale',
  'olore',
  'ajosa',
  'piogu',
  'tudau',
  'algia',
  'craba',
  'linta',
  'bulza',
  'lilla',
  'russu',
  'lente',
  'pingu',
  'tediu',
  'mussa',
  'arese',
  'veste',
  'marga',
  'spada',
  'putzu',
  'auldu',
  'ojudu',
  'erema',
  'usciu',
  'piulu',
  'deura',
  'ninnu',
  'cracu',
  'biscu',
  'biere',
  'circu',
  'abetu',
  'aguza',
  'parcu',
  'arile',
  'firmu',
  'daboi',
  'ortza',
  'muina',
  'cicia',
  'giugi',
  'cucai',
  'oddeu',
  'caida',
  'cocoi',
  'saliu',
  'monti',
  'sinnu',
  'nione',
  'manca',
  'frius',
  'lenza',
  'aoare',
  'giana',
  'bidea',
  'grina',
  'paris',
  'lintu',
  'probe',
  'dogni',
  'fieru',
  'fruga',
  'elusu',
  'trazu',
  'idria',
  'oliau',
  'pilla',
  'isula',
  'pinna',
  'ibetu',
  'gesso',
  'folza',
  'custu',
  'fairi',
  'balcu',
  'fache',
  'foddi',
  'limba',
  'monte',
  'lanza',
  'monza',
  'crinu',
  'mudai',
  'abusu',
  'cramu',
  'bruke',
  'falta',
  'segai',
  'etica',
  'fuere',
  'menti',
  'densu',
  'puniu',
  'criau',
  'eticu',
  'fustu',
  'urulu',
  'minai',
  'cardu',
  'ideau',
  'buosu',
  'zensu',
  'brutu',
  'ernia',
  'cotza',
  'salai',
  'finta',
  'secus',
  'flora',
  'sudda',
  'pantu',
  'caita',
  'resai',
  'atuau',
  'trumu',
  'anzis',
  'madau',
  'mommo',
  'varre',
  'stria',
  'danti',
  'sagru',
  'checu',
  'manos',
  'mimia',
  'zitzi',
  'feria',
  'pulga',
  'ebbia',
  'ladru',
  'leare',
  'ziziu',
  'tanda',
  'cassu',
  'farre',
  'sicau',
  'armai',
  'muidu',
  'chima',
  'fruca',
  'grida',
  'madri',
  'dalli',
  'flatu',
  'sabiu',
  'cagau',
  'guria',
  'pampa',
  'gomma',
  'orgua',
  'obilu',
  'aereu',
  'obiga',
  'brocu',
  'genna',
  'intru',
  'perda',
  'portu',
  'boboi',
  'clamu',
  'vantu',
  'cubau',
  'tapiu',
  'reinu',
  'cilca',
  'massa',
  'grupu',
  'baddi',
  'truvu',
  'bagna',
  'cughe',
  'cuile',
  'moire',
  'bulba',
  'colza',
  'chicu',
  'balzu',
  'genia',
  'ancia',
  'funna',
  'baulu',
  'gopai',
  'bruju',
  'subra',
  'amaru',
  'greve',
  'ressa',
  'spicu',
  'fatuu',
  'niali',
  'ilixi',
  'zorra',
  'stali',
  'ojire',
  'mustu',
  'bolau',
  'sunda',
  'gruke',
  'mascu',
  'ciufu',
  'donzi',
  'patiu',
  'dulze',
  'eritu',
  'atras',
  'segus',
  'nurra',
  'summa',
  'atuai',
  'cucau',
  'tantu',
  'nossi',
  'bubbu',
  'folla',
  'mella',
  'sacru',
  'radiu',
  'farsa',
  'panza',
  'friai',
  'ruosu',
  'pegus',
  'lanta',
  'ojida',
  'tapai',
  'solte',
  'curba',
  'pinta',
  'sogru',
  'colvu',
  'rullu',
  'trogu',
  'tabbi',
  'tradu',
  'cuche',
  'addia',
  'seada',
  'cesta',
  'stafa',
  'fulca',
  'colda',
  'rione',
  'soddu',
  'sadis',
  'cuegu',
  'armas',
'tirat',
'salat',
'donas',
'fidas',
'sudas',
'curat',
'colat',
'pigas',
'colas',
'penat',
'cicat',
'tremu',
'spias',
'dorat',
'coias',
'bisas',
'befas',
'penas',
'negas',
'tenni',
'tocat',
'ortit',
'bicat',
'svias',
'molis',
'bufas',
'pesat',
'molit',
'sanas',
'matis',
'dosas',
'undat',
'atuas',
'pedit',
'fumas',
'cicas',
'coiat',
'andat',
'tocas',
'miras',
'atuat',
'belat',
'bogat',
'armat',
'finit',
'undas',
'salas',
'bicas',
'picas',
'matas',
'fadat',
'narat',
'sicas',
'bisat',
'pudas',
'sudat',
'citas',
'fumat',
'nadat',
'papat',
'picat',
'andas',
'pecat',
'citat',
'bolat',
'tudat',
'pipat',
'pecas',
'donat',
'belas',
'giuas',
'megas',
'movis',
'pidat',
'fidat',
'ghiat',
'pungi',
'tenit',
'tiras',
'lamat',
'duras',
'furas',
'girat',
'dosat',
'bogas',
'matat',
'pipas',
'spiat',
'sulat',
'scuat',
'gosat',
'segas',
'tingi',
'durat',
'pesas',
'tudai',
'naras',
'bocis',
'minas',
'criat',
'frias',
'megat',
'cagat',
'finis',
'giras',
'betat',
'resas',
'crias',
'votas',
'mirat',
'pedis',
'pigat',
'calas',
'sonas',
'sanat',
'giuat',
'bolas',
'melat',
'timit',
'filas',
'sonat',
'timmi',
'tudas',
'betas',
'segat',
'movit',
'matit',
'murat',
'sviat',
'pagas',
'scuas',
'tifat',
'tudas',
'ghias',
'minat',
'resat',
'lingi',
'gosas',
'sciis',
'papas',
'furat',
'troci',
'friit',
'cucas',
'morri',
'pudat',
'befat',
'tifas',
'lamas',
'nadas',
'cucat',
'muras',
'melas',
'doras',
'cagas',
'ortis',
'filat',
'bufat',
'votat',
'calat',
'timis',
'friis',
'curas',
'tudat',
'negat',
'pidas',
'bocit',
'tenis',
'pagat',
'sicat',
'podis',
'friat',
'sulas',
'fadas',
]
