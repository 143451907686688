export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const GAME_CLAIM = 'AGATA SU FUEDDU!'
export const LOGO_DARK = 'logo-white.png'
export const LOGO_WHITE = 'logo-color.png'

export const WIN_MESSAGES = ['Beni fatu!', 'Pagu togu!', 'Andat beni!']
export const GAME_COPIED_MESSAGE = 'Giogu copiau in is apuntus!'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'No ddu faint is lìteras!'
export const WORD_NOT_FOUND_MESSAGE = 'No s\'agatat su fueddu'
export const HARD_MODE_ALERT_MESSAGE =
  'Podis scioberai sa modalidadi difìcili sceti a s\'inghitzu!'
export const HARD_MODE_DESCRIPTION =
  'Calisisiat lìtera chi scoberris dda depis poni candu torras a provai!'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Po biri mellus is coloris'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `TA LASTIMA! Su fueddu fut ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Poni ${guess} innoi ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `In su fueddu ddui depit essi ${letter}`
export const ENTER_TEXT = 'Manda'
export const DELETE_TEXT = 'Scancella'
export const STATISTICS_TITLE = 'Statìsticas'
export const GUESS_DISTRIBUTION_TEXT = 'Spainadura de is provas'
export const NEW_WORD_TEXT = 'Fueddu nou intra  '
export const SHARE_TEXT = 'Cumpartzi'
export const TOTAL_TRIES_TEXT = 'Totali de is provas'
export const SUCCESS_RATE_TEXT = 'Percentuali de bìncidas'
export const CURRENT_STREAK_TEXT = 'Filera de bìncidas'
export const BEST_STREAK_TEXT = 'Mellus filera'
export const LINK_GOOGLE_TRADUZIONE = (solution: string) =>
  `<a href=https://googlethatforyou.com?q=${solution} target=_blank>Ecco la traduzione! =)</a>`